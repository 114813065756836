import { set } from "lodash";

import { Order_By } from "../../generated/graphql";

import { CollapsibleTableHeader } from "./types";

/**
 * Creates a table header configuration object.
 *
 * @param name - The key used to identify the header, typically corresponding to a field in the data.
 * @param label - The display name of the header, shown as the column title in the table.
 * @param sortable - A boolean indicating if the column is sortable (default is `false`).
 * @returns A `CollapsibleTableHeader` object with the specified properties.
 */
export const createTableHeader = (
  name: string,
  label: string,
  sortable: boolean = false
): CollapsibleTableHeader => ({
  name,
  label,
  sortable,
});

/**
 * Creates a column configuration object for the table.
 *
 * @param name - The key used to identify the column, typically corresponding to a field in the data.
 * @param content - The content to be rendered within the column's cells, which can be any type of data or component.
 * @returns An object representing the column with its name and content.
 */
export const createTableColumn = (
  name: string,
  content: JSX.Element
): { name: string; content: JSX.Element } => ({
  name,
  content,
});

/**
 * Creates a label object used for table headers or columns.
 *
 * @param name - The key associated with the label, usually representing a field in the data.
 * @param label - The display name associated with the key, used as the column title or identifier.
 * @returns An object containing the name and label.
 */
export const createLabel = (
  name: string,
  label: string
): { name: string; label: string } => ({
  name,
  label,
});

/**
 * Generates an object with the specified field and sorting direction.
 *
 * @param field - The field to sort by.
 * @param direction - The sort direction (`Order_By.Asc` or `Order_By.Desc`). If undefined, sorting is not applied.
 * @returns An object with the field as the key and direction as the value.
 */
export const generateOrderBy = (
  field: string | undefined,
  direction: Order_By | undefined
) => {
  if (!field) return {};
  return set({}, field, direction);
};
