import { Vehicle } from "../../../../../../backend/src/vehicles/vehicle.entity";
import { useCardRow } from "../../../providers/CardRowProvider";
import { useFleets } from "../../../providers/FleetProvider";

import ActionButtons from "./ActionButtons";
import Card from "./Card";
import { CardLink } from "./VehicleCard";

export type HistoryVehicleCardVehicle = {
  vehicleId?: Vehicle["id"] | null;
  year?: Vehicle["year"] | null;
  make?: Vehicle["make"] | null;
  model?: Vehicle["model"] | null;
  plate?: Vehicle["plate"] | null;
  mileage?: Vehicle["mileage"] | null;
  state?: Vehicle["state"] | null;
  vin?: Vehicle["vin"] | null;
  imageUrl?: Vehicle["imageUrl"] | null;
  carNickname?: string | null;
  customImageUrl?: string | null;
  vehicleTags?:
    | {
        tagId?: number | null;
      }[]
    | null;
};

export type RequestCardType = {
  usersFleetVehicles?: HistoryVehicleCardVehicle | null;
  createdAt?: string | null;
  id?: number | null;
  updatedAt?: string | null;
};

const RequestHistoryCard: React.FunctionComponent<{
  request: RequestCardType;
}> = ({ request }) => {
  const { cardProps } = useCardRow();
  const { currentFleetId } = useFleets();
  const vehicle = request.usersFleetVehicles;

  return (
    <CardLink href={`/request/${request.id}?fleetId=${currentFleetId}`}>
      <Card
        {...cardProps}
        displayCheckbox={false}
        displayNotification={false}
        actionButtons={<ActionButtons vehicleId={vehicle?.vehicleId} />}
      />
    </CardLink>
  );
};

export default RequestHistoryCard;
