import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import GridViewIcon from "@mui/icons-material/GridView";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";

import { useAccountSettings } from "../providers/AccountSettings/AccountSettingsProvider";
import { VehicleView } from "../providers/AccountSettings/vehicleViewUtils";

export default function VehicleViewSelector() {
  const { settings, saveView } = useAccountSettings();

  const handleChange = (
    _event: React.MouseEvent<HTMLElement>,
    newView: VehicleView | null
  ) => {
    if (!newView) return;

    saveView(newView);
  };

  return (
    <ToggleButtonGroup
      size="small"
      value={settings.view}
      exclusive
      onChange={handleChange}
    >
      <ToggleButton value={VehicleView.List}>
        <FormatListBulletedIcon />
      </ToggleButton>
      <ToggleButton value={VehicleView.Card}>
        <GridViewIcon />
      </ToggleButton>
    </ToggleButtonGroup>
  );
}
