import {
  Badge,
  Box,
  Checkbox,
  Stack,
  TableCell,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import Link from "next/link";

import { useCardRow } from "../../../providers/CardRowProvider";
import VehiclesMoreMenu from "../VehiclesMoreMenu";

export type RowContent = {
  content?: string | null;
  isBold?: boolean;
  notificationCount?: number;
  maxWidth?: string;
}[];

function FormattedBadge({
  unreadMessageCount,
}: {
  unreadMessageCount: number | undefined;
}) {
  const theme = useTheme();

  if (!unreadMessageCount) return null;

  return (
    <Badge
      badgeContent={unreadMessageCount?.toString()}
      max={99}
      sx={{
        "& .MuiBadge-badge": {
          color: "white",
          backgroundColor: theme.palette.error.main,
        },
      }}
    />
  );
}

const TableGridItem = ({
  content,
  isBold,
  unreadMessageCount,
  url,
  maxWidth,
}: {
  content: string | null | undefined;
  isBold?: boolean;
  unreadMessageCount?: number;
  url: string;
  maxWidth?: string;
}) => {
  return (
    <TableCell>
      <Link href={url}>
        <Box display={"flex"} alignContent={"center"}>
          <Stack direction={"row"} spacing={3} alignItems="center">
            <FormattedBadge unreadMessageCount={unreadMessageCount} />

            <Typography
              maxWidth={maxWidth ?? "8vw"}
              textOverflow="ellipsis"
              variant="body2"
              alignSelf={"center"}
              overflow={"hidden"}
              whiteSpace={"nowrap"}
              fontSize={isBold ? 16 : 14}
              fontWeight={isBold ? 700 : 400}
            >
              {/* If there's no content, we still want to render _something_ so links still show up correctly. We use a non-breaking space so that it still shows up in the rendered output. */}
              {content?.length ? content : <>&nbsp;</>}
            </Typography>
          </Stack>
        </Box>
      </Link>
    </TableCell>
  );
};

const HOVER_STYLE = {
  outline: "solid",
  outlineWidth: 2,
  outlineColor: "primary.main",
};

const Row = ({
  rowContent,
  actionButtons,
  displayCheckbox,
  displayMoreMenu,
  url,
}: {
  rowContent: RowContent;
  actionButtons: JSX.Element;
  displayCheckbox?: boolean;
  displayMoreMenu?: boolean;
  url: string;
}) => {
  const { isSelected, handleSelected, isHovered, setIsHovered } = useCardRow();

  const extraStyle = isHovered || isSelected ? HOVER_STYLE : undefined;

  return (
    <TableRow
      sx={{
        borderRadius: "25px",
        boxShadow: "0px 2px 12px rgba(0, 0, 0, 0.1)",
        alignSelf: "center",
        alignItems: "center",
        ...extraStyle,
        "& td": { border: 0, borderSpacing: 20 },
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {displayCheckbox && (
        <TableCell padding="checkbox">
          <Checkbox
            disableRipple
            checked={isSelected}
            color={"primary"}
            onChange={(e) => handleSelected(e)}
          />
        </TableCell>
      )}
      {rowContent.map((item, index) => (
        <TableGridItem
          content={item.content}
          isBold={item.isBold ?? false}
          key={`vehicles-row-${index}`}
          unreadMessageCount={item.notificationCount}
          url={url}
          maxWidth={item.maxWidth}
        />
      ))}
      <TableCell>
        <Link href={url}>
          <Box display={"flex"} justifyContent={"center"}>
            {actionButtons}
          </Box>
        </Link>
      </TableCell>
      {displayMoreMenu && (
        <TableCell sx={{ maxWidth: 4 }}>
          <Box display={"flex"} justifyContent={"flex-end"}>
            <VehiclesMoreMenu />
          </Box>
        </TableCell>
      )}
    </TableRow>
  );
};

export default Row;
