import { Box, lighten, Typography, useTheme } from "@mui/material";

import { RequestState } from "../../../../../../backend/common/states";
import { WorkflowType } from "../../../../../../backend/common/workflow.types";
import {
  isRequestPendingAction,
  RequestStatusDisplayVehicleText,
  SaasRequestStatusDisplayVehicleText,
} from "../../../services/request.service";
import ActionBaseButton from "../../ActionBaseButton";

export const OpenRequestsButtons = ({
  requestState,
  workflowType,
}: {
  requestState: string | null | undefined;
  workflowType?: WorkflowType;
}) => {
  const isSaas = WorkflowType.SAAS === workflowType;
  const title = isSaas
    ? SaasRequestStatusDisplayVehicleText.get(requestState ?? "")
    : RequestStatusDisplayVehicleText.get(requestState ?? "");

  if (isRequestPendingAction(requestState ?? "")) {
    return <PendingActionButton title={title} />;
  }

  if (isSaas && requestState === RequestState.SCHEDULED_DROPOFF) {
    return <ActionBaseButton title="Close Request" />;
  }

  return <ActiveRequestCurrentStatus title={title} />;
};

export const PendingActionButton = ({
  title,
  bgColor,
}: {
  title: string | undefined;
  bgColor?: string;
}) => {
  return <ActionBaseButton title={title ?? ""} bgColor={bgColor} />;
};

export const ViewOrdersButton = () => {
  const theme = useTheme();
  return (
    <PendingActionButton
      title={"View Order"}
      bgColor={lighten(theme.palette.secondary.main, 0.85)}
    />
  );
};

export const ActiveRequestCurrentStatus = ({
  title,
}: {
  title: string | undefined;
}) => {
  return (
    <Box whiteSpace={"nowrap"} py={1.7} width={"100%"}>
      <Typography variant="body1" align="center">
        {title ?? ""}
      </Typography>
    </Box>
  );
};

export const StartServiceButton = ({
  vehicleId,
}: {
  vehicleId: number | null | undefined;
}) => {
  if (!vehicleId) return null;
  return <ActionBaseButton title="Start Service" />;
};
