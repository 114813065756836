import { useEffect, useRef } from "react";

import CancelIcon from "@mui/icons-material/Cancel";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  FormControl,
  Icon,
  IconButton,
  InputAdornment,
  SxProps,
  TextField,
} from "@mui/material";

export default function SearchBar({
  searchTerm,
  onChange,
  autoFocus = false,
  placeholder = "Search",
  sx = { parent: {}, child: {} },
}: {
  searchTerm: string;
  onChange: (term: string) => void;
  autoFocus?: boolean;
  placeholder?: string;
  sx?: { parent: SxProps; child: SxProps };
}) {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current && autoFocus) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <Box
      mb={{ xs: 2, md: 0 }}
      display={"flex"}
      justifyContent={"flex-start"}
      sx={sx.parent}
    >
      <FormControl
        sx={{ width: "fit-content", ...sx.child }}
        variant="outlined"
        size="small"
      >
        <TextField
          name="searchTerm"
          size="small"
          type={"text"}
          value={searchTerm}
          onChange={(event) => onChange(event?.target.value)}
          inputRef={autoFocus ? inputRef : null}
          InputLabelProps={
            !searchTerm ? { shrink: false, style: { paddingLeft: "2rem" } } : {}
          }
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {searchTerm && (
                  <IconButton
                    onClick={() => {
                      onChange("");
                    }}
                  >
                    <CancelIcon color="secondary" />
                  </IconButton>
                )}
              </InputAdornment>
            ),
            startAdornment: (
              <InputAdornment position="start">
                <Icon>
                  <SearchIcon color="secondary" />
                </Icon>
              </InputAdornment>
            ),
          }}
          label={placeholder}
        />
      </FormControl>
    </Box>
  );
}
