import { forwardRef } from "react";

import assert from "assert";

import Link from "next/link";

import { useCardRow } from "../../../providers/CardRowProvider";

import ActionButtons from "./ActionButtons";
import Card from "./Card";
import { ParentLinkProps, VehicleCardType } from "./types";

export const CardLink = forwardRef<HTMLAnchorElement, ParentLinkProps>(
  ({ href, children }, ref) => {
    return (
      <Link href={href} passHref legacyBehavior>
        <a ref={ref}>{children}</a>
      </Link>
    );
  }
);

CardLink.displayName = "CardLink";

const VehicleCard = ({
  fleetVehicle,
  displayCheckbox = true,
}: {
  fleetVehicle: VehicleCardType;
  displayCheckbox?: boolean;
}) => {
  assert(fleetVehicle, "Vehicle not found");
  const { roDetailsUrl, startServiceUrl, cardProps } = useCardRow();

  return (
    <CardLink href={roDetailsUrl ?? startServiceUrl}>
      <Card
        {...cardProps}
        displayCheckbox={displayCheckbox}
        displayNotification={!displayCheckbox}
        actionButtons={<ActionButtons vehicleId={fleetVehicle.vehicleId} />}
      />
    </CardLink>
  );
};

export default VehicleCard;
