import { Box } from "@mui/material";

import { isRequestPendingAction } from "shared/services/request.service";

import {
  isWorkflowType,
  WorkflowType,
} from "../../../../../../backend/common/workflow.types";
import { useCardRow } from "../../../providers/CardRowProvider";
import { useFleets } from "../../../providers/FleetProvider";
import {
  RequestStatusDisplayVehicleText,
  SaasRequestStatusDisplayVehicleText,
} from "../../../services/request.service";
import ActionBaseButton from "../../ActionBaseButton";

import {
  OpenRequestsButtons,
  StartServiceButton,
  ViewOrdersButton,
} from "./TableButtons";

const OpenRequestButton = ({
  requestStatus,
  workflowType,
}: {
  requestStatus: string;
  workflowType: WorkflowType | undefined;
}) => {
  if (!isRequestPendingAction(requestStatus, workflowType)) {
    return (
      <OpenRequestsButtons
        requestState={requestStatus}
        workflowType={workflowType}
      />
    );
  } else {
    return (
      <Box display="flex" justifyContent={"center"} width={"100%"}>
        <ActionBaseButton
          title={
            WorkflowType.SAAS === workflowType
              ? SaasRequestStatusDisplayVehicleText.get(requestStatus)
              : RequestStatusDisplayVehicleText.get(requestStatus)
          }
        />
      </Box>
    );
  }
};

const ActionButtons = ({
  vehicleId,
}: {
  vehicleId: number | null | undefined;
}) => {
  const { currentFleetId: fleetId } = useFleets();
  const { openRequest, isFromVehiclesPage, isFromHistoryPage } = useCardRow();
  if (!vehicleId || !fleetId) return null;

  if ((isFromVehiclesPage && openRequest) || isFromHistoryPage) {
    return (
      <Box display="flex" justifyContent={"space-between"}>
        <ViewOrdersButton />
      </Box>
    );
  }
  const workflowType = isWorkflowType(openRequest?.workflowType)
    ? openRequest?.workflowType
    : undefined;
  return (
    <Box display="flex" justifyContent={"space-between"} overflow={"hidden"}>
      {openRequest ? (
        <OpenRequestButton
          requestStatus={openRequest.status ?? ""}
          workflowType={workflowType}
        />
      ) : (
        <StartServiceButton vehicleId={vehicleId} />
      )}
    </Box>
  );
};

export default ActionButtons;
