import { useState } from "react";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Box, IconButton, Menu, MenuItem } from "@mui/material";
import Link from "next/link";

import { useCardRow } from "../../providers/CardRowProvider";

type MenuItems = {
  label: string;
  href?: string;
  onClick?: () => void;
};

const VehiclesMoreMenu = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { vehicleSettingsUrl, setIsHovered } = useCardRow();
  const handleButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setIsHovered(false);
    setAnchorEl(null);
  };

  const menuItems: MenuItems[] = [
    {
      label: "Vehicle Info",
      href: vehicleSettingsUrl,
    },
  ];

  return (
    <>
      <IconButton
        size="small"
        sx={{ p: 0.2 }}
        onClick={(e) => handleButtonClick(e)}
      >
        <MoreVertIcon fontSize="small" />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        {menuItems?.map((item) => {
          return (
            <MenuItem
              key={item.label}
              sx={{ fontSize: "14px" }}
              value={item.label}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                item.onClick?.();
              }}
            >
              {item.href ? (
                <Link href={item.href}>{item.label}</Link>
              ) : (
                <Box>{item.label}</Box>
              )}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

export default VehiclesMoreMenu;
