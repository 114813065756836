import { Box } from "@mui/material";

import { CardRowProvider } from "../../../providers/CardRowProvider";

import RequestHistoryCard, { RequestCardType } from "./RequestHistoryCard";
import VehicleCard from "./VehicleCard";
import { VehicleCardType } from "./types";

const VehicleCardView: React.FunctionComponent<{
  vehicles?: VehicleCardType[];
  requests?: RequestCardType[];
  isMobile: boolean;
  displayCheckboxes?: boolean;
}> = ({ vehicles, requests, isMobile, displayCheckboxes }) => {
  const reversedRequests = requests?.slice().reverse();
  return (
    <>
      <Box
        display={"flex"}
        flexWrap={"wrap"}
        justifyContent={isMobile ? "center" : "flex-start"}
      >
        {vehicles?.map((vehicle, idx) => {
          return (
            <Box p={1} key={`vehicle-card-${vehicle.vehicleId}-${idx}`}>
              <CardRowProvider fleetVehicle={vehicle}>
                <VehicleCard
                  fleetVehicle={vehicle}
                  displayCheckbox={displayCheckboxes}
                />
              </CardRowProvider>
            </Box>
          );
        })}
        {reversedRequests?.map((request) => {
          return (
            <Box p={1} key={"request-card-" + request.id}>
              <CardRowProvider
                fleetVehicle={request.usersFleetVehicles}
                request={request}
              >
                <RequestHistoryCard request={request} />
              </CardRowProvider>
            </Box>
          );
        })}
      </Box>
    </>
  );
};

export default VehicleCardView;
