import { Box, Checkbox, Stack, Typography, useTheme } from "@mui/material";

import VehicleImage, {
  VehicleImageHeight,
  VehicleImageWidth,
} from "shared/components/vehicles/VehicleImage";

import { useCardRow } from "../../../providers/CardRowProvider";
import VehiclesMoreMenu from "../VehiclesMoreMenu";

export type CardProps = {
  primaryText: string;
  secondaryText: string;
  tertiaryText: string | null | undefined;
  defaultTertiaryText?: string;
  imageUrl: string | null | undefined;
  vehicleId: number | null | undefined;
  altText: string;
  actionButtons: JSX.Element;
  displayCheckbox?: boolean;
  displayNotification?: boolean;
};

const Card = ({
  primaryText,
  secondaryText,
  tertiaryText,
  defaultTertiaryText = "N/A",
  imageUrl,
  vehicleId,
  altText,
  actionButtons,
  displayCheckbox,
}: CardProps) => {
  const theme = useTheme();
  const { isSelected, handleSelected, isHovered, setIsHovered } = useCardRow();
  const hoverStyle =
    isHovered || isSelected
      ? {
          outline: "solid",
          outlineWidth: 2,
        }
      : undefined;

  return (
    <Box
      width={"16rem"}
      borderRadius={5}
      overflow="hidden"
      display={"flex"}
      bgcolor={"background.paper"}
      boxShadow={3}
      sx={{
        ...hoverStyle,
        cursor: "pointer",
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Stack width={"100%"}>
        <Box px={2} pt={2.5}>
          <Stack width={"100%"} gap={1}>
            {displayCheckbox && (
              <Box
                display={"flex"}
                justifyContent="space-between"
                mb={1}
                mt={-1}
              >
                <Checkbox
                  disableRipple
                  checked={isSelected}
                  color={"primary"}
                  onChange={(e) => handleSelected(e)}
                  onClick={(e) => e.stopPropagation()}
                  sx={{ p: 0 }}
                />
                <Box alignSelf={"center"}>
                  <VehiclesMoreMenu />
                </Box>
              </Box>
            )}
            <Box px={0.5}>
              <VehicleImage
                imageUrl={imageUrl}
                alt={altText}
                widthUsage={VehicleImageWidth.card}
                heightUsage={VehicleImageHeight.card}
                vehicleId={vehicleId}
              />
            </Box>
            <Stack
              display={"flex"}
              justifyContent={"center"}
              flexWrap={"nowrap"}
              textAlign={"center"}
              gap={1}
            >
              <Typography
                variant="h6"
                textOverflow="ellipsis"
                overflow={"hidden"}
                fontWeight={700}
                whiteSpace={"nowrap"}
                maxWidth={"14rem"}
              >
                {primaryText}
              </Typography>
              <Typography
                variant="body1"
                textOverflow="ellipsis"
                overflow={"hidden"}
                whiteSpace={"nowrap"}
                maxWidth={"14rem"}
              >
                {secondaryText}
              </Typography>
              <Typography
                variant="body1"
                textOverflow="ellipsis"
                overflow={"hidden"}
                whiteSpace={"nowrap"}
                sx={{
                  color: tertiaryText
                    ? theme.palette.text.primary
                    : theme.palette.grey[500],
                }}
                fontSize={16}
                maxWidth={"14rem"}
              >
                {tertiaryText && tertiaryText !== ""
                  ? tertiaryText
                  : defaultTertiaryText}
              </Typography>
            </Stack>
          </Stack>
        </Box>
        <Box pt={2}>{actionButtons}</Box>
      </Stack>
    </Box>
  );
};

export default Card;
