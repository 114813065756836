import { Box, darken, useTheme } from "@mui/material";

import { useCardRow } from "../providers/CardRowProvider";

const ActionBaseButton = ({
  title = "View",
  bgColor,
}: {
  title?: string;
  bgColor?: string;
}) => {
  const theme = useTheme();
  const { isHovered } = useCardRow();
  const backgroundColor = bgColor ?? theme.palette.accent1.main;

  const buttonStyle = {
    px: 1.5,
    py: 2,
    backgroundColor: isHovered ? darken(backgroundColor, 0.3) : backgroundColor,
    width: "100%",
    whiteSpace: "nowrap",
    fontSize: 16,
    fontWeight: 800,
    color: theme.palette.getContrastText(backgroundColor),
    textAlign: "center",
  };

  return (
    <Box sx={buttonStyle} height={"100%"}>
      {title}
    </Box>
  );
};

export default ActionBaseButton;
