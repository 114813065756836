import { useRouter } from "next/router";

import { Order_By } from "shared/generated/graphql";

function isOrderBy(orderBy: string): orderBy is Order_By {
  return Object.values(Order_By).includes(orderBy as Order_By);
}

type UseTableHeadWithSortProps = {
  defaultField?: string;
  defaultDirection?: Order_By;
};

const DEFAULT_SORT_DIRECTION = Order_By.DescNullsLast;

function useRouterSort(props: UseTableHeadWithSortProps) {
  const router = useRouter();
  const params = router.query;

  const sortField: string | undefined =
    params.sortField && !Array.isArray(params.sortField)
      ? params.sortField
      : props.defaultField;

  const defaultDirection = props.defaultDirection ?? DEFAULT_SORT_DIRECTION;

  const sortDirection: Order_By =
    params.sortDirection &&
    !Array.isArray(params.sortDirection) &&
    isOrderBy(params.sortDirection)
      ? params.sortDirection
      : defaultDirection;

  const updateSort = (sortField?: string, sortDirection?: Order_By) => {
    const query = { ...params, sortField, sortDirection };
    const isDefaultField =
      sortField === props.defaultField &&
      sortDirection === DEFAULT_SORT_DIRECTION;
    if (isDefaultField) {
      delete query.sortField;
      delete query.sortDirection;
    }

    router.push({ query });
  };

  return {
    sortField,
    sortDirection,
    updateSort,
  };
}
export default useRouterSort;
